// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'jquery'
import 'bootstrap'
import 'mark.js'
import 'datatables.mark.js'
import 'datatables.net'
import 'datatables.net-bs5'
import 'jquery-mask-plugin'
import '../css/site.scss'
import '../src/adjust_jquery'
import '../images/gdhlogo_sm.gif'

Rails.start()
ActiveStorage.start()